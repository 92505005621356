export const START_POLL = 'START_POLL';
//export const START_POLL_OPTION = 'START_POLL_OPTION_1';
//export const START_POLL_OPTION_2 = 'START_POLL_OPTION_2';
//export const START_POLL_OPTION_3 = 'START_POLL_OPTION_3';
//export const START_POLL_OPTION_4 = 'START_POLL_OPTION_4';
export const VOTED_POLL_OPTION = 'VOTED_POLL_OPTION';
//export const VOTED_POLL_OPTION_2 = 'VOTED_POLL_OPTION_2';
//export const VOTED_POLL_OPTION_3 = 'VOTED_POLL_OPTION_3';
//export const VOTED_POLL_OPTION_4 = 'VOTED_POLL_OPTION_4';
export const SET_ROOM_NAME = 'SET_ROOM_NAME';
export const GET_ROOM_NAME = 'GET_ROOM_NAME';
export const SET_USER_NAME = 'SET_USER_NAME';
export const GET_USER_NAME = 'GET_USER_NAME';
export const SET_USER_ID = 'SET_USER_ID';
export const GET_USER_ID = 'GET_USER_ID';
export const SET_ROOM = 'SET_ROOM';
export const GET_ROOM = 'GET_ROOM';
export const SET_USER = 'SET_USER';
export const GET_USER = 'GET_USER';
